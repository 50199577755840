import React, { Fragment, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Link from 'next/link';
// import Back from '../../public/images/back_rounded.png';
// import logo from '../../public/images/logo-b.png';
import logo from "../../public/images/Logo-vertical-inverse.png"
import { useDispatch, useSelector } from "react-redux";
import { FaFacebookF } from "react-icons/fa"
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { Button } from '../../common_components/CommonFields';
import * as UserAction from "../../actions/userActions";
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import Image from "next/image"
import Router from "next/router";
import { MdEmail, MdOutlineCall } from 'react-icons/md';
import { HiOutlineChevronDown } from 'react-icons/hi';
import classNames from 'classnames';
import { FaSquareXTwitter } from 'react-icons/fa6';

export default function NacigationMenu({ closeMenu }) {
  const dispatch = useDispatch();
  const [rendered, setRendered] = useState(false);
  const { logoutUser, loadUser } = bindActionCreators(UserAction, dispatch);
  const [isShow,setIsShow] = useState(false);
  const { user, isAuthenticated } = useSelector(
    (state) => state.user
  );
  // useEffect(() => {
  //   if (localStorage.getItem("token") && !rendered) {
  //     loadUser()
  //     setRendered(true)
  //   }
  //   return (() => {
  //     setRendered(false);
  //   })
  // }, [user])
  const onLogout = () => {
    setTimeout(() => {
      closeMenu();
      Router.push("/");
      localStorage.removeItem("token");
      toast.success("You are Logged Out");
      logoutUser();
    }, 1000);
  }
  return (
    <div className='bg-white w-100   pt-4 mt-2  navigation-menu d-flex align-items-center flex-column'>
      <div className='text-center navigation-image mt-2 mb-4'>
        <Image src={logo} className='logo-img' alt={`img-${logo}`} />
      </div>
      <div className=' text-center'>
        <Link href='/' className='my-2'>
          <a>
            <h5 className='py-1  c-pointer' onClick={(e) => closeMenu()}>
              Home
            </h5>
          </a>
        </Link>
        <div className='dropdown'>
          <div style={{direction:"ltr"}}>
        <Link href='/electric-vehicles' className='my-2'>
          {/* <a className=''> */}
          <a className='d-inline-block'>
            <h5 className='py-1  c-pointer' onClick={() => closeMenu()}>E-
              Vehicles
            </h5>
          </a>
        </Link>
        <HiOutlineChevronDown className="dropbtn d-inline-block fs-5 mb-1 ms-2" onClick={() => setIsShow(!isShow) } />
        </div>
        <div className={classNames("mob-dropdown-content border",
        {
          "d-none" : isShow == false
        },{
          "d-block" : isShow == true
        }
        )}>
            <Link href={"/electric-two-wheelers"}><a className='head text-start'>Two wheeler</a></Link>
            <Link href={"/electric-scooters"}><a className='border-left ms-3 ps-3 sub-head text-start'>Scooter</a></Link>
            <Link href={"/electric-bikes"}><a className='border-left  ms-3 ps-3 sub-head text-start'>Bike</a></Link>
            <Link href={"/electric-cars"}><a className='head text-start'>Four wheeler</a></Link>
            <Link href={"/electric-commercial-vehicles"}><a className='head text-start'>Commercial</a></Link>
            <Link href={"/electric-vehicles?type=6"}><a className='head text-start'>All</a></Link>
          </div>
          </div>
        <Link href='/electric-vehicle-companies' className='my-2'>
          <a>
            <h5 className='py-1  c-pointer' onClick={(e) => closeMenu()}>
              Companies
            </h5>
          </a>
        </Link>
        <Link href='/compare' className='my-2'>
          <a>
            <h5 className='py-1  c-pointer' onClick={(e) => closeMenu()}>
              Compare
            </h5>
          </a>
        </Link>
        <Link href='/blog' className='my-2'>
          <a>
            <h5 className='py-1   c-pointer' onClick={(e) => closeMenu()}>
              Blogs
            </h5>
          </a>
        </Link>
        <Link href='/news' className='my-2'>
          <a>
            <h5 className='py-1   c-pointer' onClick={(e) => closeMenu()}>
              News
            </h5>
          </a>
        </Link>
        {/* <Link href='/upcoming' className='my-2'>
          <a>
            <h5 className='py-1  c-pointer' onClick={(e) => closeMenu()}>
              Upcoming
            </h5>
          </a>
        </Link> */}
        <Link href='/saved-vehicles' className='my-2'>
          <a>
            <h5 className='pt-1 mb-2 c-pointer' onClick={(e) => closeMenu()}>Saved vehicles</h5>
          </a>
        </Link>
        <Link href='/contact-us' className='my-2'>
          <a>
            <h5 className='pt-1 pb-4 mb-2 c-pointer' onClick={(e) => closeMenu()}>Contact Us</h5>
          </a>
        </Link>



      </div>

      <hr
        style={{
          margin: '0px !important',
          width: '-webkit-fill-available',
          opacity: '0.1',
        }}
      />
      <div className='px-4  text-start mt-4 pt-1'>
        <div className='text-center' style={{ direction: 'ltr' }}>
          <span
            className='px-1 border rounded-2 color-d-gray'
            style={{ fontSize: '18px' }}
          >
            {' '}
            <MdOutlineCall />
          </span>
          <span className='fs-14 ms-3'>
            <a href='tel:+919099900348' target='_blank' rel='noreferrer'>
              +91 9099900348
            </a>
          </span>
        </div>
        <div className='mt-3  mb-3 text-center' style={{ direction: 'ltr' }}>
          <span
            className='px-1 border rounded-2 color-d-gray'
            style={{ fontSize: '18px' }}
          >
            {' '}
            <MdEmail />
          </span>
          <span className='fs-14 ms-3'>
            <a
              href='mailto:info@evindia.online'
              target='_blank'
              rel='noreferrer'
            >
              info@evindia.online
            </a>
          </span>
        </div>
        <div className=' d-flex text-center my-4' style={{ direction: 'ltr' }}>
          <a
            href='https://www.facebook.com/evindia.online/'
            target='_blank'
            rel='noreferrer'
            aria-label='link for EVINDIA facebook page'
          >
            <i className='border color-d-gray px-2 py-1 rounded-2'>
              <FaFacebookF className='' style={{ fontSize: '16px' }} />
            </i>
          </a>
          <a
            href='https://twitter.com/EVIndiaonline'
            target='_blank'
            rel='noreferrer'
            aria-label='link for EVINDIA twitter page'
          >
            <i className='border color-d-gray px-2 py-1 ms-3 rounded-2'>
              {/* <FaTwitter className='' style={{ fontSize: '16px' }} /> */}
              <FaSquareXTwitter className='' style={{ fontSize: '16px' }} />
            </i>
          </a>
          <a
            href='https://www.linkedin.com/company/evindiaonline/'
            target='_blank'
            rel='noreferrer'
            aria-label='link for EVINDIA linkedin page'
          >
            <i className='border color-d-gray ms-3 px-2 py-1 rounded-2'>
              <FaLinkedinIn className='' style={{ fontSize: '16px' }} />
            </i>
          </a>
          <a
            href='https://youtube.com/channel/UCywB8N5ChjnfTNrvS4q8ZIA'
            target='_blank'
            rel='noreferrer'
            aria-label='link for EVINDIA youtube page'
          >
            <i className='border color-d-gray px-2 py-1 ms-3 rounded-2'>
              <FaYoutube className='' style={{ fontSize: '16px' }} />
            </i>
          </a>
          <a
            href='https://www.instagram.com/evindia.online/?utm_medium=copy_link'
            target='_blank'
            rel='noreferrer'
            aria-label='link for EVINDIA instagram page'
          >
            <i className='border color-d-gray px-2 py-1 ms-3 rounded-2'>
              <AiFillInstagram className='' style={{ fontSize: '16px' }} />
            </i>
          </a>
        </div>
        <div className='text-center'>
          <Link href='/termsandconditions'>
            <a>
              <span className='fs-14 color-d-gray pe-3 border-right'>
                Terms
              </span>
            </a>
          </Link>
          <Link href='/privacy-policy'>
            <a>
              <span className='fs-14 color-d-gray px-3 border-right'> Privacy</span>
            </a>
          </Link>
          <Link href='/career'>
            <a>
              <span className='fs-14 color-d-gray ps-3'> career</span>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}

